
import { createGlobalStyle } from 'styled-components';

export const lightTheme = {

};

export const darkTheme = {
    backgroundColor: '#212529',
    backGroundNaoLido: '#ffc107',
    textColor: '#fff',
    primaryColor: '#38aadc',
    textColorEscuro: '#005f33',
    secondaryColor: '#d3d3d3',
};

// Create a Styled Component for global theme application (optional)
export const GlobalStyles = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.backgroundColor};
    color: ${({ theme }) => theme.textColor} !important;
  }

  header, nav, th, td {
      background-color: ${({ theme }) => theme.backgroundColor} !important;
      color: ${({ theme }) => theme.textColor} !important;
  }
  .nav-item > a:hover {
      background: #08807b !important;
  }
  h1, th, td, a, label{
      color:  ${({ theme }) => theme.secondaryColor} !important;
  }
  .form-floating > label {
      color: ${({ theme }) => theme.textColorEscuro} !important; 
  }


`;