import React, {useContext, useEffect, useState} from 'react';

import {useNavigate, useParams} from "react-router-dom";
import {AuthContext} from "../../contexts/Auth/AuthContext";
import {useApi} from "../../hooks/useApi";
import HistoricoDocumento from "./HistoricoDocumento";
import Loading from "../../components/Loading";
import BarraProgressoDocumento from "../../components/BarraProgressoDocumento";

export default function VisualizarDocumento() {
    let {documento_id} = useParams();

    let navigate = useNavigate();
    let auth = useContext(AuthContext)
    let token = localStorage.getItem('authToken')
    let api = useApi(token);
    const [documentoId, setDocumentoId] = useState('')
    const [remetente, setRemetente] = useState(0)
    const [titulo, setTitulo] = useState('')
    const [tipoDocumento, setTipoDocumento] = useState('')
    const [numeroDocumento, setNumeroDocumento] = useState('')
    const [corpoTexto, setCorpoTexto] = useState('')
    const [descricao, setDescricao] = useState('')
    const [prazoData, setPrazoData] = useState('')
    const [prazoHorario, setPrazoHorario] = useState('')
    const [protocolo, setProtocolo] = useState('')
    const [visualizado, setVisualizado] = useState('')
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        // @ts-ignore
      setDocumentoId(documento_id);
        // @ts-ignore
        handleReadOne(documento_id)
    }, [documento_id]);




    const handleReadOne = async (documento_id: string, tipo?: string) => {
     setLoading(true)
        if (tipo === "historico") {
            let response = await api.documento_historico.mostrarMudanca(documento_id);
            setDocumentoId(response.data.id)
            setTipoDocumento(response.data.tipo_documento)
            setRemetente(response.data.remetente_user_id)
            setCorpoTexto(response.data.corpo_texto)
            setNumeroDocumento(response.data.numero_documento)
            setTitulo(response.data.titulo)
            setDescricao(response.data.descricao)
            setPrazoData(response.data.data_prazo)
            setPrazoHorario(response.data.horario_prazo)
            setProtocolo(response.data.protocolo)
            setVisualizado(response.data.visualizacao)
        } else {
            let response = await api.documento.readOne(documento_id);
            setDocumentoId(response.data.id)
            setTipoDocumento(response.data.tipo_documento)
            setRemetente(response.data.remetente_user_id)
            setCorpoTexto(response.data.corpo_texto)
            setNumeroDocumento(response.data.numero_documento)
            setTitulo(response.data.titulo)
            setDescricao(response.data.descricao)
            setPrazoData(response.data.data_prazo)
            setPrazoHorario(response.data.horario_prazo)
            setProtocolo(response.data.protocolo)
            setVisualizado(response.data.visualizacao)

        }

        setLoading(false)
    }
    function handleVoltar(e: any) {
        e.preventDefault();
        navigate('/documento')
    }

    function handleResposta(e: any, documento_id: string) {
        e.preventDefault();
        navigate(`/documento/responder/${documento_id}`)
    }

    return (

        <div className="font-verde p-5">

            {loading && <Loading/>}
            <div className='d-flex justify-content-lg-between align-items-center'>
                <div><h1>Visualizar Documento</h1></div>

            </div>
            <BarraProgressoDocumento documento_id={documentoId} />
            <hr/>
            <div className="mb-3">


                    <span style={{marginRight: "20px"}}>
                        <button className="btn btn-primary btn-verde " onClick={
                            (e) => handleVoltar(e)
                        }>Voltar
                        </button>
                    </span>
                {
                    remetente !== auth.user?.id &&
                    <span>
                                <button className="btn btn-primary btn-verde" onClick={(e) =>
                                    handleResposta(e, documentoId)

                                }>Responder
                                </button>
                            </span>
                }


            </div>
            <div className="row">
                <div className="col text-center">
                    <h1>{titulo}</h1>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    {tipoDocumento} nº {numeroDocumento}
                </div>
            </div>
            <div className="col mt-2">
                {corpoTexto}
            </div>
            <div className="col mt-3">
                <span className="fw-bold"> Descrição:  </span>{descricao}
            </div>
            <div className="col">
                <span className="fw-bold"> Data do Prazo: </span> {prazoData} {prazoHorario}
            </div>
            <div className="col">
                <span className="fw-bold">Data do Protocolo: </span> {protocolo}
            </div>

            <HistoricoDocumento documento_id={documento_id}/>


        </div>
    )
}