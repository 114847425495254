import docsmart_logo_horizontal_light_mode from "../assets/docsmart_logo_horizontal_light_mode.png";
import docsmart_logo_horizontal_dark_mode from "../assets/docsmart_logo_horizontal_dark_mode.png";
import React, {useContext, useEffect, useState} from "react";
import {AuthContext} from "../contexts/Auth/AuthContext";
export default function LogoSuperior() {
    let context = useContext(AuthContext)

    const [isDarkTheme, setIsDarkTheme] = useState(context.themeMode)
    useEffect(() => {
        console.log(context.themeMode)
        setIsDarkTheme(context.themeMode)
    }, [context.themeMode]);

    return (
        <>
            {
                !isDarkTheme &&
                <img src={docsmart_logo_horizontal_light_mode} width='200'/>
            }
            {
                isDarkTheme &&
                <img src={docsmart_logo_horizontal_dark_mode} width='200'/>
            }

        </>

    )
}