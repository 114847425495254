import React, {useContext, useEffect, useState} from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.js';

import Routes from './routes';
import Topo from "./components/Topo";
import Footer from "./components/Footer";
import {AuthContext} from "./contexts/Auth/AuthContext";
import {lightTheme, darkTheme, GlobalStyles} from './themes';
import styled, {ThemeProvider} from 'styled-components'
function App() {

    let context = useContext(AuthContext)

    const [isDarkTheme, setIsDarkTheme] = useState(context.themeMode)
    useEffect(() => {
        console.log(context.themeMode)
        setIsDarkTheme(context.themeMode)
    }, [context.themeMode]);
  return (
      <ThemeProvider theme={isDarkTheme ? darkTheme : lightTheme}>

      <div className="container-fluid font-verde"  style={{paddingLeft: 0, paddingRight: 0}}>
          <GlobalStyles />
          <Topo/>
          <Routes/>
          <Footer/>
      </div>
      </ThemeProvider>
  );
}

export default App;
