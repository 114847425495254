import React, {useContext, useEffect, useState} from 'react';
import "./processo.css"
import {useNavigate, useParams} from "react-router-dom";
import {AuthContext} from "../../contexts/Auth/AuthContext";
import {useApi} from "../../hooks/useApi";
import Loading from "../../components/Loading";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCircleLeft,
    faCircleRight,
    faEye, faEyeSlash,
    faLock,
    faLockOpen,
    faPenNib,
    faTrash
} from "@fortawesome/free-solid-svg-icons";


export default function VisualizarProcesso() {
    let {processo_id} = useParams();

    let navigate = useNavigate();
    let auth = useContext(AuthContext)
    let token = localStorage.getItem('authToken')
    let api = useApi(token);

    const [descricao, setDescricao] = useState('')
    const [observacao, setObservacao] = useState('')
    const [processoId, setProcessoId] = useState('')
    const [passoAtual, setPassoAtual] = useState('')
    const [confidencial, setConfidencial] = useState()
    const [visibilidade, setVisibilidade] = useState()
    const [setor, setSetor] = useState('')

    const [documentos, setDocumentos] = useState([])


    const [loading, setLoading] = useState(false);
    useEffect(() => {
        // @ts-ignore
        setProcessoId(processo_id);
        // @ts-ignore
        handleReadOne(processo_id)
        handleDocumentoPorProcesso(processo_id)
    }, [processo_id]);


    const handleReadOne = async (processo_id: string) => {
        setLoading(true)

        let response = await api.processo.readOne(processo_id);
        setDescricao(response.data.descricao)
        setObservacao(response.data.observacao)
        setPassoAtual(response.data.passo_atual)
        setConfidencial(response.data.confidencial)
        setVisibilidade(response.data.visibilidade)
        setSetor(response.data.setor)
        setLoading(false)
    }

    function handleVoltar(e: any) {
        e.preventDefault();
        navigate('/processo')
    }

    async function handleDocumentoPorProcesso(processo_id: any) {
        let response = await api.documento.documentoPorProcesso(processo_id);
        setDocumentos(response.data)
    }

    function handleView(e: any, documento_id: string) {
        e.preventDefault();
        navigate(`/documento/visualizar/${documento_id}`)
    }


    return (

        <div className="font-verde p-5">

            {loading && <Loading/>}
            <div className='d-flex justify-content-lg-between align-items-center'>
                <div><h1>Detalhes do Processo nº {processoId}</h1></div>

            </div>

            <hr/>
            <div className="mb-3">


                    <span style={{marginRight: "20px"}}>
                        <button className="btn btn-primary btn-verde " onClick={
                            (e) => handleVoltar(e)
                        }>Voltar
                        </button>
                    </span>


            </div>
            <div className="row">
                <div className="col ">
                    <h4>Descrição:</h4>
                    {descricao}
                </div>
                <div className="col ">
                    <h4>Observação:</h4>
                    {observacao}
                </div>

            </div>
            <div className={"row mt-3"}>
                <div className="col ">
                    <h4>Status:</h4>
                    <span className={`${passoAtual} p-2 rounded`}>{passoAtual}</span>
                </div>
                <div className="col ">
                    <h4>Confidencialidade:</h4>
                    {confidencial === 1 ?
                        <FontAwesomeIcon color="red" icon={faLock}/> :
                        <FontAwesomeIcon icon={faLockOpen}/>}
                </div>
                <div className="col ">
                    <h4>Visibilidade:</h4>

                    {visibilidade === 1 ?
                        <FontAwesomeIcon color="red" icon={faLock}/> :
                        <FontAwesomeIcon icon={faLockOpen}/>}
                </div>

                <div className="col">
                    <h4>Setor:</h4>
                    {setor}
                </div>
            </div>
            <hr/>
            <div className="row">
                <div className="col">
                    <div><h1>Documentos</h1></div>

                    <table className="table table-striped table-hover">
                        <thead>
                        <tr>
                            <th>C</th>
                            <th>Nº do documento</th>
                            <th>Título</th>
                            <th>Resposta ao</th>
                            <th>Remetente</th>
                            <th>Destinatário</th>
                            <th>Criado em</th>
                            <th className="text-center">Ações</th>
                        </tr>
                        </thead>
                        <tbody>

                        {documentos.map(({
                                             confidencial,
                                             remetente_user_id,
                                             id,
                                             titulo,
                                             resposta_documento_id,
                                             destinatario,
                                             destinatario_user_id,
                                             numero_documento,
                                             setor_id,
                                             remetente,
                                             created_at,
                                             visualizacao
                                         }) => (
                            <tr key={id} data-tooltip-id="my-tooltip"
                                data-tooltip-content={visualizacao ? `Visualizado em ${visualizacao}` : 'Não visualizado'}>
                                <td>{confidencial === 1 ?
                                    <FontAwesomeIcon color="red" icon={faLock}/> :
                                    <FontAwesomeIcon icon={faLockOpen}/>}

                                </td>
                                <td>{numero_documento}</td>
                                <td>{visualizacao ? '' :
                                    <FontAwesomeIcon icon={faEyeSlash} style={{color: 'red'}}/>}{titulo}</td>
                                <td>
                                    {
                                        resposta_documento_id &&
                                        <>
                                            <FontAwesomeIcon onClick={(e) => handleView(e, resposta_documento_id)}
                                                             className="p-2"
                                                             icon={faEye}/>
                                        </>

                                    }

                                </td>
                                <td>{remetente}</td>
                                <td>{destinatario}</td>
                                <td>{created_at}</td>
                                <td className="text-center">
                                    <FontAwesomeIcon className="p-2" onClick={(e) => handleView(e, id)}
                                                     icon={faEye}/>


                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>


        </div>
    )
}