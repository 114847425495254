import moment from "moment";

export function EssaDataNaoPodeEstarNoPassado(date: string) {
    console.log(date)
    const agora = moment().format("YYYY-MM-DD");
    const data_aux = moment(date).format();
    console.log(agora, data_aux)
    console.log(agora > data_aux)
    if (agora > data_aux) {
        return true;
    }
    return false;
}

export function EssaDataNaoPodeSerMaiorQueEssa(dateInicio: string,dateFinal: string,) {

    const dateInicio_ = moment(dateInicio).format();
    const dateFinal_ = moment(dateFinal).format();

    if (dateInicio_ > dateFinal_) {
        return false;
    }
    return true;
}
