import React, {useState, useEffect, useContext} from 'react';
import {AuthContext} from "../contexts/Auth/AuthContext";
import {useNavigate} from "react-router-dom";

const initialSeconds = 900; // 15 minutos em segundos

function Cronometro() {
    const [seconds, setSeconds] = useState<number>(initialSeconds);
    const [isActive, setIsActive] = useState<boolean>(true);
    const auth = useContext(AuthContext);
    const navigate = useNavigate();
    useEffect(() => {
        const savedSecondsString = localStorage.getItem('savedSeconds');
        const savedIsActiveString = localStorage.getItem('savedIsActive');

        if (savedSecondsString && savedIsActiveString) {
            const savedSeconds = parseInt(savedSecondsString);
            const savedIsActive = savedIsActiveString === 'true';

            setSeconds(savedSeconds);
            setIsActive(savedIsActive);
        }
    }, []);
    async function handleLogout() {
        navigate('/');
        auth.signout();

    }
    useEffect(() => {
        let interval: NodeJS.Timeout;

        if (isActive && seconds > 0) {
            interval = setInterval(() => {
                setSeconds(prevSeconds => prevSeconds - 1);
            }, 1000); // Atualizar a cada segundo

        } else {
            handleLogout()
        }



        return () => clearInterval(interval);
    }, [isActive, seconds]);

    useEffect(() => {
        if (seconds === 0) {
            setIsActive(false);
        }
        localStorage.setItem('savedSeconds', String(seconds));
        localStorage.setItem('savedIsActive', String(isActive));
    }, [seconds, isActive]);

    const formatTime = (time: number): string => {
        const minutes = Math.floor(time / 60);
        const remainingSeconds = time % 60;
        return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
    };

    return (
        <>

           {formatTime(seconds)}
        </>
    );
}

export default Cronometro;