import React, {useEffect, useState} from 'react'
import {useApi} from "../../hooks/useApi";
import "./dashboard.css"
import {faFolderOpen, faUsersViewfinder} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Bar, Doughnut} from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);
export default function Dashboard() {
    let token = localStorage.getItem('authToken')
    let api = useApi(token);

    const data = {
        labels: ["Número de Documentos Por tipo"],
        datasets: [
            {
                label: 'Oficio',
                data: [10],
                backgroundColor:   'rgba(255, 99, 132, 0.2)',

            },
            {
                label: 'Recibo',
                data: [11],
                backgroundColor:   'rgba(255, 3, 132, 0.2)',

            },
        ],
    }
    const [documentoTotal, setDocumentoTotal] = useState()
    const [usuarioTotal, setUsuarioTotal] = useState()

    useEffect(() => {
        handleTotais()
    }, [])

    async function handleTotais() {
        let response = await api.dashboard.totais();
        setDocumentoTotal(response.data.documentos)
        setUsuarioTotal(response.data.usuarios)
    }

    return (
        <div className="p-5">
            <div><h1>Dashboard</h1></div>
            <hr/>
            <div className="row">
                <div className="col text-center">
                    <div className='h3'><FontAwesomeIcon className=" fa-2xl"
                                                         icon={faFolderOpen}/></div>
                    <div className='h1'>{documentoTotal}</div>


                </div>
                <div className="col text-center">
                    <div className='h3'><FontAwesomeIcon className=" fa-2xl" icon={faUsersViewfinder}/></div>
                    <div className='h1'>{usuarioTotal}</div>


                </div>
            </div>
            <hr/>
            <div className="row text-center">
                <div className="col-6">
                    <Bar data={data}
                              options={{
                                  responsive: true,
                                  plugins: {
                                      legend: {
                                          position: 'top' as const,
                                      },
                                      title: {
                                          display: true,
                                          text: 'Documentos/Tipo do Documento',
                                      },
                                  },

                              }}/>
                </div>
            </div>
        </div>
    )
}