import React, {useContext} from "react";
import Menu from "./Menu";
import StatusLoginTop from "./StatusLoginTop";
import {AuthContext} from "../contexts/Auth/AuthContext";
import MenuAdministrador from "./MenuAdministrador";
import "./Topo.css"
import VerificarToast from "./VerificarToast";
import MenuExterno from "./MenuExterno";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLightbulb, faMoon} from "@fortawesome/free-solid-svg-icons";

function Topo() {
    const auth = useContext(AuthContext);
    return (
        <header className="topo ">

            {auth.user && <>
                {
                    <>
                        <StatusLoginTop/>
                        {
                            auth.user &&
                            <>
                                <MenuAdministrador/>
                            </>

                        }


                        <VerificarToast/>
                    </>
                }

            </>}

            {!auth.user && <>

                <Menu/>
            </>}

        </header>
    )
}

export default Topo;