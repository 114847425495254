import React, {useEffect, useState} from "react";
import {AuthContext} from "./AuthContext";
import {User} from "../../types/user";
import {useApi} from "../../hooks/useApi";


export const  AuthProvider = ({children}: {children: JSX.Element}) => {

    const [user, setUser] = useState<User | null>(null);
    const [documentoSemVisualizacao, setDocumentoSemVisualizacaoState] = useState(0);
    const [themeMode, setThemeModeState] = useState(false);
    let token = localStorage.getItem('authToken')
    const api = useApi(token);

    function setThemeMode(theme: any) {
        setThemeModeState(theme)
    }
    function setDocumentoSemVisualizacao(number: any) {
        setDocumentoSemVisualizacaoState(number)
    }
   useEffect( () => {
        const validateToken = async() => {
            const storageData = localStorage.getItem('authToken');


            if(storageData) {
                try {
                    const response = await api.validateToken(storageData);
                    console.log(response)
                    if(response.data.user) {
                        setUser(response.data.user);
                    }
                }catch (e) {
                    signoutSemAPi()
                }

            }
        }
        validateToken();
    }, [api])


    const signin = async (email: string, password: string, empresa: string) => {
        const data = await api.signin(email, password, empresa);
        if(data.user  && data.token) {
            setUser(data.user);
            setUserLocalStorage(JSON.stringify(data.user))
            setToken(data.token.token)
            return true;
        }
        return false;
    }

    const signout = async() => {
        await api.logout().then(() => {
            localStorage.clear();
            setUser(null);
        });
    }

    const signoutSemAPi = async() => {

            localStorage.clear();
            setUser(null);

    }

    const setToken = (token: string) => {
        localStorage.setItem('authToken', token);
    }

    const setUserLocalStorage = (user: string) => {
        localStorage.setItem('authUser', user);
    }


    return(
        <AuthContext.Provider value={{user, signin, signout, signoutSemAPi, documentoSemVisualizacao, setDocumentoSemVisualizacao, setThemeMode, themeMode}}>
            {children}
        </AuthContext.Provider>
    )
}
