import React, {useContext} from "react";
import {Link, useNavigate} from "react-router-dom";
import {AuthContext} from "../contexts/Auth/AuthContext";

import {faEnvelope, faHouse, faRightToBracket} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Tooltip} from 'react-tooltip'
import LogoSuperior from "./LogoSuperior";
import './Menu.css'
function Menu() {
    const auth = useContext(AuthContext);


    return (
        <nav className="navbar navbar-expand-lg navbar-light" style={{background: '#f5f5f5'}}>
            <div className="container-fluid">
                <a className="navbar-brand" href="#"><LogoSuperior /></a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse " id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <Link className="nav-link  text-lg-center " to="/">
                                <FontAwesomeIcon className=" fa-2xl" icon={faHouse}/>
                                <div className="mt-lg-1 item-menu d-none d-md-block ">Página Principal</div>
                                <span className="d-lg-none">Página Principal</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link text-lg-center" to="/login">
                                <FontAwesomeIcon className=" fa-2xl" icon={faRightToBracket}/>
                                <div className="mt-lg-1 item-menu d-none d-md-block ">Login</div>
                                <span className="d-lg-none">Login</span></Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link text-lg-center" to="/contato" >
                                <FontAwesomeIcon icon={faEnvelope} className=" fa-2xl"/>
                                <div className="mt-lg-1 item-menu d-none d-md-block ">Contato</div>
                                <span className="d-lg-none">Contato</span>
                            </Link>
                        </li>

                 
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default Menu;