import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)


export const useModal= () => ({
    timer: (titulo: string, texto: string, icon: string) => {

        MySwal.fire({
            title: titulo,
            text: texto,
            // @ts-ignore
            icon: icon,
            timer: 1500,
            showConfirmButton: false,
        })

    },
    erroTimer: (titulo: string, texto: string, icon: string) => {
        MySwal.fire({
            title: titulo,
            text: texto,
            icon: "error",
            timer: 1500,
            showConfirmButton: false,
        })
    },
    semTimerWarning: (titulo: string, texto: string) => {
        MySwal.fire({
            title: titulo,
            text: texto,
            icon: "warning",
            showConfirmButton: true,
        })
    },
})