import React, {useEffect, useState} from "react";
import Pusher from "pusher-js";


export default function EditorCooperativo() {
    const [texto, setTexto] = useState([])
    let allTextos: any[] = [];
    useEffect(() => {
        Pusher.logToConsole = true;

        const pusher = new Pusher('45ecad701a708b4980e9', {
            cluster: 'us2'
        });

        const channel = pusher.subscribe('my-channel');
        channel.bind('my-event', function(data: any) {
            allTextos.push(data);
            // @ts-ignore
            setTexto(allTextos);

        });
    }, []);



    return(
        <>
           <textarea id="editor-cooperativo" onChange={(e:any) => setTexto(e.target.value)} value={texto}>

           </textarea>
        </>
    )
}