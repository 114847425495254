import React, {useState} from "react";
import ContadorTextFiled from "../../components/ContadorTextField";
import {useApi} from "../../hooks/useApi";
import {useModal} from "../../hooks/useModal";

function Contato() {
    let api = useApi('');
    let modal = useModal();
    const [nome, setNome] = useState('')
    const [motivo, setMotivo] = useState('')
    const [email, setEmail] = useState('')
    const [mensagem, setMensagem] = useState('')

    const handleSubmitForm = async (e: any) => {

        e.preventDefault();
        const response = await api.contato.insert(nome, email,  motivo, mensagem);
        if (response.status === 200) {
            modal.timer("Contato", "Mensagem enviada com sucesso!", "success");
        }
        if (response.status === 400) {
            modal.timer("Contato", "Erro ao enviar mensagem!", "success");
        }

    }
    function handleClearInput() {
        setNome('')
        setMotivo('')
        setMensagem("")
        setEmail('')
    }
    return(
        <>
            <div className="p-5">
                <div><h1>Contato</h1></div>
                <hr/>
                <form onSubmit={(e) => handleSubmitForm(e)}>
                    <div className="row">
                        <div className="col">
                            <div className="form-floating">
                                <input type="text" className="form-control" id="floatingInput"
                                       value={nome}
                                       maxLength={255}
                                       onChange={(e) => setNome(e.target.value)}
                                       placeholder="Título" required/>
                                <label htmlFor="floatingInput">Nome</label>
                            </div>
                            <div className="form-floating mt-3">
                                <input type="email" className="form-control" id="floatingInput"
                                       value={email}
                                       maxLength={255}
                                       onChange={(e) => setEmail(e.target.value)}
                                       placeholder="Título" required/>
                                <label htmlFor="floatingInput">E- mail</label>
                            </div>
                            <div className="form-floating mt-3">
                                <select id="floatingInput" value={motivo} className="form-select"
                                        onChange={(e) => setMotivo(e.target.value)}
                                        required={true}
                                >
                                    <option value="">Selecione</option>
                                    <option value="SUGESTAOCRITICA">Sugestões e Críticas</option>
                                    <option value="SOLICITACAO">Solicitações em Geral</option>
                                    <option value="PROBLEMASTECNICOS">Problemas Técnicos</option>
                                </select>
                                <label htmlFor="floatingInput">Motivo do Contato</label>
                            </div>
                            <div className="col mt-4">
                                <div className="form-floating">
                        <textarea className="form-control" style={{height: '200px'}} value={mensagem}
                                  placeholder="Leave a comment here"
                                  id="floatingTextarea2"
                                  maxLength={500}
                                  required
                                  onChange={(e) => setMensagem(e.target.value)}
                        ></textarea>
                                    <label htmlFor="floatingTextarea2">Mensagem</label>
                                    <ContadorTextFiled texto={mensagem} tamanho={500}/>
                                </div>
                            </div>
                            <div className="row">

                                <div className="col-md-6">
                                    <button type="submit"
                                            className="btn btn-verde btn-primary btn-block mt-4 w-100">Enviar
                                    </button>
                                </div>
                                <div className="col-md-6">
                                    <button type="button" onClick={handleClearInput}
                                            className="btn btn-verde btn-primary btn-block mt-4 w-100">Limpar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default Contato;