import React, {useContext, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLightbulb, faMoon} from "@fortawesome/free-solid-svg-icons";
import {AuthContext} from "../contexts/Auth/AuthContext";

function Footer() {
    const context = useContext(AuthContext);
    return(
        <>
                <span style={{height: "300px"}}></span>
            <div className="text-center align-items-center  fixed-bottom p-2 d-flex justify-content-between text-white fw-bold " style={{background: '#005f33'}}>
                    Copyright 2024
                <div>
                <button className="font-verde" onClick={() => context.setThemeMode(true)}><FontAwesomeIcon icon={faMoon}/></button>
                <button className="font-verde"  onClick={() => context.setThemeMode(false)}><FontAwesomeIcon icon={faLightbulb}/></button>
                </div>
            </div>


        </>
    )
}

export default Footer;