import React from "react";
import {Routes, Route} from 'react-router-dom';
import Login from "./pages/Login/Login";
import Home from "./pages/Home";
import {RequireAuth} from "./contexts/Auth/RequireAuth";
import PerfilPage from "./pages/Administrador/Perfil";
import UsuarioPage from "./pages/Administrador/Usuario";
import TipoDocumento from "./pages/TipoDocumento";
import Reuniao from "./pages/Reuniao";
import DocumentoPage from "./pages/Documento";
import Contato from "./pages/Contato";
import VisualizarDocumento from "./pages/Documento/VisualizarDocumento";
import TesteIA from "./pages/TesteIA";
import AlterarDocumento from "./pages/Documento/AlterarDocumento";
import ResponderDocumento from "./pages/Documento/ResponderDocumento";
import Grafico from "./pages/Grafico";
import Relatorio from "./pages/Relatorio";
import Dashboard from "./pages/Dashboard";
import EditorCooperativo from "./components/EditorCooperativo";
import LoginTwoFactor from "./pages/LoginTwoFactor/LoginTwoFactor";
import ProcessoPage from "./pages/Processo";
import VisualizarProcesso from "./pages/Processo/VisualizarProcesso";
import AlterarProcesso from "./pages/Processo/AlterarProcesso";

const MainRoutes = () => {
    return (
        <Routes>
            <Route path="/login" element={<Login/>}/>
            <Route path="/" element={<Home/>}/>
            <Route path="/perfil" element={<RequireAuth><PerfilPage/></RequireAuth>}/>
            <Route path="/usuario" element={<RequireAuth><UsuarioPage/></RequireAuth>}/>
            <Route path="/tipo-documento" element={<RequireAuth><TipoDocumento/></RequireAuth>}/>
            <Route path="/reuniao" element={<RequireAuth><Reuniao/></RequireAuth>}/>
            <Route path="/documento" element={<RequireAuth><DocumentoPage/></RequireAuth>}/>
            <Route path="/processo" element={<RequireAuth><ProcessoPage/></RequireAuth>}/>
            <Route path="/processo/visualizar/:processo_id" element={<RequireAuth><VisualizarProcesso/></RequireAuth>}/>
            <Route path="/processo/alterar/:processo_id" element={<RequireAuth><AlterarProcesso/></RequireAuth>}/>
            <Route path="/documento/responder/:documento_resposta_id"
                   element={<RequireAuth><ResponderDocumento/></RequireAuth>}/>
            <Route path="/documento/visualizar/:documento_id"
                   element={<RequireAuth><VisualizarDocumento/></RequireAuth>}/>
            <Route path="/documento/alterar/:documento_id" element={<RequireAuth><AlterarDocumento/></RequireAuth>}/>
            <Route path="/contato" element={<Contato/>}/>
            <Route path="/teste" element={<TesteIA/>}/>
            <Route path="/graficos" element={<RequireAuth><Grafico/></RequireAuth>}/>
            <Route path="/relatorios" element={<RequireAuth><Relatorio/></RequireAuth>}/>
            <Route path="/dashboard" element={<RequireAuth><Dashboard/></RequireAuth>}/>
            <Route path="/editor" element={<EditorCooperativo/>}/>
        </Routes>
    )
}

export default MainRoutes;