import React, {useContext, useEffect, useState} from "react";
import './Login.css';
import {AuthContext} from "../../contexts/Auth/AuthContext";
import {useNavigate} from "react-router-dom";
import logo from '../../assets/lbmdocs.png'
import {useApi} from "../../hooks/useApi";
import {faRightToBracket} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import LogoCentral from "../../components/LogoCentral";
function Login() {
    let token = localStorage.getItem('authToken')
    let api = useApi(token);
    const auth = useContext(AuthContext);
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [messageError, setMessageError] = useState(false)
    const [empresas, setEmpresas] = useState([])
    const [empresa, setEmpresa] = useState('');

    useEffect(() => {
        handleReadAllEmpresas()
    }, []);

    const handleReadAllEmpresas = async () => {
        let response = await api.empresa.readAll();
        setEmpresas(response.data);
    }
    const handleLogin = async (e: any) => {
        e.preventDefault()
        if(email && password) {
            const isLogged = await auth.signin(email, password, empresa);

            if(isLogged){
                navigate('/');
            } else {
                setMessageError(true)
            }
        }
    }

    return (
        <div className=" text-center">
            <main className="form-signin">
                <form onSubmit={(e) => handleLogin(e)}>
                    <LogoCentral />
                    <h1 className="h3 mb-3 fw-normal">Login</h1>

                    <div className="form-floating">
                        <input type="email" className="form-control" id="floatingInput" value={email}
                              required onChange={e => setEmail(e.target.value)} placeholder="name@example.com"/>
                        <label htmlFor="floatingInput">E-mail</label>
                    </div>
                    <div className="form-floating mt-2">
                        <input type="password" className="form-control" id="floatingPassword" value={password}
                               required onChange={e => setPassword(e.target.value)} placeholder="Password"/>
                        <label htmlFor="floatingPassword">Senha</label>
                    </div>

                    <div className="form-floating mt-2">
                        <select value={empresa}  className="form-select" aria-label="Seletor de exemplo" required
                                onChange={(e) => setEmpresa(e.target.value)}>
                            <option value="">Selecione uma</option>
                            {empresas.map(({id, nome_fantasia}) => (
                                <option key={id} value={id}>{nome_fantasia}</option>
                            ))}
                        </select>
                        <label htmlFor="floatingPassword">Empresa</label>
                    </div>

                    {messageError && <span className="small text-danger">Usuário ou senhas inválidos</span>}
                    <button className="w-100 btn btn-lg btn-primary mt-5 btn-verde" type="submit"><FontAwesomeIcon icon={faRightToBracket} /></button>
                </form>
            </main>
        </div>
    )
}

export default Login;