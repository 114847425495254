import React, {useContext, useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowsRotate,
    faCircleLeft,
    faCircleRight,
    faEye, faEyeSlash, faFilter, faFilterCircleXmark, faKey, faLock, faLockOpen,
    faMagnifyingGlass,
    faPenNib,
    faTrash
} from "@fortawesome/free-solid-svg-icons";
import Loading from "../../components/Loading";
import {useApi} from "../../hooks/useApi";
import {useModal} from "../../hooks/useModal";
import {AuthContext} from "../../contexts/Auth/AuthContext";
import {useNavigate} from "react-router-dom";
import {fitlerDocumento} from "../../types/filter_documento";
import {Tooltip} from "react-tooltip";
import './processo.css'

export default function ListarProcesso() {
    const [processos, setProcessos] = useState([])
    const [filter, setFilter] = useState<fitlerDocumento>()
    const [loading, setLoading] = useState(false);
    let navigate = useNavigate();
    let token = localStorage.getItem('authToken')
    let api = useApi(token);
    let modal = useModal();
    let auth = useContext(AuthContext)
    useEffect(() => {
        handleReadAll();
    }, [])

    const handleReadAll = async () => {
        setLoading(true)
        let response = await api.processo.readAll();
        setProcessos(response.data);
        setLoading(false)
    }

    function handleView(e: any, processo_id: string) {
        e.preventDefault();
        navigate(`/processo/visualizar/${processo_id}`)
    }

    function handleAlterarVisualizacao(e: any, processo_id: string) {
        e.preventDefault();
        navigate(`/processo/alterar/${processo_id}`)
    }
    return (
        <>
            {loading && <Loading/>}
            <div className="p-1 mt-2 mb-5 font-verde">
                <hr/>
                <table className="table table-striped table-hover">
                    <thead>
                    <tr>
                        <th>C</th>
                        <th>V</th>
                        <th>Nº do Processo</th>
                        <th>Descrição</th>
                        <th>Criado por</th>
                        <th>Status</th>
                        <th>Perfil Mínimo</th>
                        <th>Setor</th>
                        <th className="text-center">Ações</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        processos.map(({
                                           atrasado,
                                           dias_atrasado,
                                           name,
                                           setor,
                                           id,
                                           perfil,
                                           passo_atual,
                                           descricao,
                                           confidencial,
                                           visibilidade,
                                       }) => (
                            <tr key={id}>
                                <td>{confidencial === 1 ?
                                    <FontAwesomeIcon color="red" icon={faLock}/> :
                                    <FontAwesomeIcon icon={faLockOpen}/>}</td>
                                <td>{visibilidade === 1 ?
                                    <FontAwesomeIcon color="red" icon={faLock}/> :
                                    <FontAwesomeIcon icon={faLockOpen}/>}</td>
                                <td>{id}</td>
                                <td>{descricao}</td>
                                <td>{name}</td>
                                <td><span className={`${passo_atual} rounded p-2 m-2`}>{passo_atual}  </span>
                                    {
                                        atrasado &&
                                            <span className="bg-dark p-2 text-light rounded"> {" "}{atrasado ? `ATRASADO há ${dias_atrasado} dia(s) ` : ""}</span>
                                    }
                                </td>
                                <td>{perfil}</td>
                                <td>{setor}</td>
                                <td className="text-center">
                                    <FontAwesomeIcon className="p-2" onClick={(e) => handleView(e, id)} icon={faEye}/>
                                    <FontAwesomeIcon onClick={(e) => handleAlterarVisualizacao(e, id)}
                                                     className="p-2"
                                                     icon={faPenNib}/>
                                    <FontAwesomeIcon className="p-2" icon={faTrash}/>

                                </td>
                            </tr>
                        ))
                    }
                    </tbody>
                </table>

                <Tooltip id="my-tooltip" className="d-none d-lg-block"/>
            </div>
        </>

    )
}