import React, {useEffect, useState} from "react";
import {useApi} from "../../hooks/useApi";
import {useModal} from "../../hooks/useModal";
import Loading from "../../components/Loading";
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowsRotate, faPenNib, faTrash} from "@fortawesome/free-solid-svg-icons";
import ContadorTextFiled from "../../components/ContadorTextField";
function ReuniaoPage() {
    let token = localStorage.getItem('authToken')
    let api = useApi(token);
    let modal = useModal();

    const [reuniaoId, setReuniaoId] = useState(0);


    const [descricao, setDescricao] = useState("");
    const [data, setData] = useState("");
    const [horario, setHorario] = useState("");
    const [participantes, setParticipantes] = useState("");
    const [pauta, setPauta] = useState("");

    const [reunioes, setReunioes] = useState([])
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        handleReadAll();
    }, [])

    const handleReadAll = async () => {
        setLoading(true)
        let response = await api.reuniao.readAll();
        setReunioes(response.data);
        setLoading(false)
    }

    const handleReadOne = async (e: any, reuniao_id: number) => {
        e.preventDefault();
        let response = await api.reuniao.readOne(reuniao_id);

        setDescricao(response.data.descricao)
        setData(response.data.data);
        setHorario(response.data.horario);
        setParticipantes(response.data.participantes);
        setPauta(response.data.pauta)
        setReuniaoId(response.data.id)
    }

    const handleSubmitForm = async (e: any) => {

        e.preventDefault();
        const response = await api.reuniao.insert(descricao, data, horario, participantes, pauta);
        if (response.status === 200) {
            modal.timer("Reunião", "Salvo com sucesso!", "success");
            handleReadAll();
        }
        if (response.status === 422) {

            modal.timer("Reunião", "Nome do perfil duplicado", "warning")
        }
    }

    const handleSubmitOne = async (e: any) => {
        e.preventDefault();

        const response = await api.reuniao.update(reuniaoId, descricao, data, horario, participantes, pauta);
        if (response.status === 200) {
            modal.timer("Reunião", "Salvo com sucesso!", "success");
            handleReadAll();
        }
        if (response.status === 422) {
            modal.timer("Reunião", "Salvo com sucesso!", "success");
        } else {
            modal.timer("Reunião", "Nome do perfil duplicado", "warning")
        }
    }

    const handleDelete = async (e: any, reuniao_id: number) => {
        e.preventDefault();

        const response = await api.reuniao.delete(reuniao_id);

        if (response.status === 200) {
            modal.timer("Reunião", "Deletado com sucesso!", "success");
            handleReadAll();
        }
        if (response.status === 422) {
            modal.timer("Reunião", "Nome do perfil duplicado", "warning")
        }
    }

    const handleClearInput = () => {
        setDescricao("")
        setData("");
        setHorario("");
        setParticipantes("");
        setPauta("")
        setReuniaoId(0)
    }

    return(
        <div  className="p-5">
            {loading && <Loading/>}
            <div>
                <h1>Reunião</h1>
                <hr/>

                <form onSubmit={reuniaoId === 0 ? (e) => handleSubmitForm(e) : (e) => handleSubmitOne(e)}>

                    <div className="row mb-4">
                        <div className="col-md-6">
                            <div className="form-floating">
                                <input type="text" className="form-control" id="floatingInput"
                                       value={descricao}
                                       onChange={(e) => setDescricao(e.target.value)}
                                       placeholder="name@example.com" required/>
                                <label htmlFor="floatingInput">Nome</label>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-floating">
                                <input type="date" className="form-control" id="floatingInput"
                                       value={data}
                                       onChange={(e) => setData(e.target.value)}
                                       placeholder="data" required/>
                                <label htmlFor="floatingInput">Data</label>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-floating">
                                <input type="time" className="form-control" id="floatingInput"
                                       value={horario}
                                       onChange={(e) => setHorario(e.target.value)}
                                       placeholder="horario" required/>
                                <label htmlFor="floatingInput">Horário</label>
                            </div>
                        </div>

                    </div>
                    <div className="row mb-4">
                        <div className="col-md-6">
                            <div className="form-floating">
                                          <textarea className="form-control tamanho_textarea" value={participantes}
                                                    placeholder="Leave a comment here"
                                                    id="floatingTextarea2"
                                                    required
                                                    maxLength={1000}
                                                    onChange={(e) => setParticipantes(e.target.value)}
                                          ></textarea>
                                <label htmlFor="floatingTextarea2">Participantes</label>
                                <ContadorTextFiled texto={participantes} tamanho={1000} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-floating">
                                          <textarea className="form-control tamanho_textarea" value={pauta}
                                                    placeholder="Leave a comment here"
                                                    id="floatingTextarea2"
                                                    required
                                                    maxLength={400}
                                                    onChange={(e) => setPauta(e.target.value)}
                                          ></textarea>
                                <label htmlFor="floatingTextarea2">Pauta</label>
                                <ContadorTextFiled texto={pauta} tamanho={400} />
                            </div>
                        </div>
                    </div>
                    <div className="row">

                        <div className="col-md-6">
                            <button type="submit"
                                    className="btn btn-verde btn-primary btn-block mt-4 w-100">{reuniaoId === 0 ? "Salvar" : 'Atualizar'}</button>
                        </div>
                        <div className="col-md-6">
                            <button type="button" onClick={handleClearInput}
                                    className="btn btn-verde btn-primary btn-block mt-4 w-100">Limpar
                            </button>
                        </div>
                    </div>
                </form>

            </div>
            <hr/>

            <div className="p-1 mt-2 mb-5 ">
                <div className='d-flex  justify-content-end w-100 align-content-end mb-3'>
                    <button className="btn btn-verde btn-primary " onClick={
                        handleReadAll
                    }><FontAwesomeIcon icon={faArrowsRotate}/>
                    </button>
                </div>
                <table className="table table-striped table-hover">
                    <thead>
                    <tr>
                        <th>Descrição</th>
                        <th>Cadastrador</th>
                        <th>Data/Hora</th>
                        <th className="text-center">Ações</th>
                    </tr>
                    </thead>
                    <tbody>

                    {reunioes.map(({id, descricao, usuario, data, horario,}) => (
                        <tr>
                            <td>{descricao}</td>
                            <td>{usuario}</td>
                            <td>{data} {horario}</td>
                            <td className="text-center"><FontAwesomeIcon onClick={(e) => handleReadOne(e, id)}
                                                                         icon={faPenNib}/> <FontAwesomeIcon
                                onClick={(e) => handleDelete(e, id)} icon={faTrash}/>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default ReuniaoPage;