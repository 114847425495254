import React from "react";
import img_loading from '../assets/gif/loading.gif';
import "./Loading.css";
 function Loading() {
    return(
    <div className="fundo-loading">
        <img src={img_loading} className="loading" />
    </div>
    )
}

export default Loading;