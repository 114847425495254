import React, {useEffect} from "react";
import {useApi} from "../hooks/useApi";

export default function TesteIA() {
    let token = localStorage.getItem('authToken')
    let api = useApi(token);
    useEffect(() => {
        Teste()
    }, []);

   async function Teste() {
        const response = await api.IA.teste();
        console.log(response)
    }

    return(
        <>
            teste
        </>
    )
}