import React, {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleMinus, faCirclePlus} from "@fortawesome/free-solid-svg-icons";
import ListarProcesso from "./ListarProcesso";
import ProcessoForm from "./ProcessoForm";


function ProcessoPage() {

    const [btnMaisMenos, setBtnMaisMenos] = useState(faCirclePlus);

    function handleBtnMaisMenos() {
        if(btnMaisMenos == faCirclePlus) {
            setBtnMaisMenos(faCircleMinus)
        } else{
            setBtnMaisMenos(faCirclePlus)
        }
    }
    return (
        <div className="p-5">
            <>
                <div>
                    <div className='d-flex justify-content-lg-between align-items-center'>
                        <div><h1>Processo <FontAwesomeIcon style={{color: 'red'}} onClick={handleBtnMaisMenos}
                                                            icon={btnMaisMenos}/></h1></div>
                    </div>
                </div>
                <hr/>
                {
                    btnMaisMenos === faCircleMinus &&
                    <ProcessoForm/>
                }

                <ListarProcesso />
            </>
        </div>

    )
}

export default ProcessoPage;