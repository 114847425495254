import React, {useContext, useEffect} from 'react';
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {useApi} from "../hooks/useApi";
import {useNavigate} from "react-router-dom";
import {AuthContext} from "../contexts/Auth/AuthContext";

export default function VerificarToast() {
    let token = localStorage.getItem('authToken')
    let api = useApi(token);
    let navigate = useNavigate()
    const context = useContext(AuthContext);
    useEffect(() => {
        handleTemDocumentosSemVisualizacao()
    }, []);

    async function handleTemDocumentosSemVisualizacao() {
        let response = await api.documento.TemDocumentosSemVisualizacao();
        if (response.data > 0) {
            context.setDocumentoSemVisualizacao(response.data )
                toast.info(
                <div onClick={navigateToPage} style={{cursor: 'pointer'}}>
                    Você possui novos documentos
                </div>, {
                    autoClose: false,
                    theme: "colored"
                })
        }
    }

    const navigateToPage = () => {
        navigate("/documento")
        toast.dismiss();
    }
    return (
        <div>

            <ToastContainer />
        </div>
    )
}