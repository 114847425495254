import React, {useContext} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRightFromBracket} from "@fortawesome/free-solid-svg-icons";
import {AuthContext} from "../contexts/Auth/AuthContext";
import {useNavigate} from "react-router-dom";
import "./StatusLoginTop.css";
import Cronometro from "./Cronometro";

export default function StatusLoginTop() {
    const auth = useContext(AuthContext);
    const navigate = useNavigate();

    async function handleLogout() {
        auth.signoutSemAPi()
    }

    return (
        <>
            <div style={{fontSize: '12px', height: '35px'}}
                 className="d-flex 	d-none d-md-block justify-content-end align-items-center text-white bg-verde">

                <span className="fw-bold p-2 label_nome_profissao">Empresa: </span>
                <span className="p-2"> {auth.user?.nome_fantasia} - {auth.user?.setor}  </span>
                <span className="fw-bold p-2 label_nome_profissao">Nome: </span>
                <span className="p-2"> {auth.user?.tratamento} {auth.user?.name} </span>

                <span className="p-2 fw-bold label_nome_profissao">Perfil: </span>

                <span className="p-2">{auth.user?.perfil} </span>
                <span className="p-2">
                <button className="btn" onClick={handleLogout}>
                       <FontAwesomeIcon style={{color: 'white'}} icon={faRightFromBracket}/>
                </button>
            </span>
                <span className="p-2"> <Cronometro/> </span>


            </div>
            <div style={{fontSize: '12px', height: '35px'}}
                 className="d-flex 	d-md-none d-sm-block justify-content-end align-items-center text-white bg-verde">
                <span className="p-2"> {auth.user?.tratamento} {auth.user?.name} | {auth.user?.perfil}</span>
                <span className="p-2">
                <button className="btn" onClick={handleLogout}>
                       <FontAwesomeIcon style={{color: 'white'}} icon={faRightFromBracket}/>
                </button>
            </span>
                <span className="p-2"> <Cronometro/> </span>


            </div>

        </>
    )
}