import React from "react";
import logo from "../../assets/docsmart_logo.svg";
import LogoCentral from "../../components/LogoCentral";

function Home() {
    return(
 <div className="container text-center mt-5">
     <LogoCentral />


 </div>
    )
}

export default Home;