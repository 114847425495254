import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

export function modalRetorno(status: number, titulo: string) {

        if (status === 200) {
            MySwal.fire({
                title: titulo,
                text: 'Salvo com sucesso',
                icon: "success",
                timer: 1500,
                showConfirmButton: false,
            })
        }
        if (status === 422) {
            MySwal.fire({
                title: titulo,
                text: "Conteúdo dubplicado",
                icon: "warning",
                timer: 1500,
                showConfirmButton: false,
            })
        }
    if (status === 401) {
        MySwal.fire({
            title: titulo,
            text: "Você não está autenticado no sistema",
            icon: "error",
            timer: 1500,
            showConfirmButton: false,
        })
    }
    if (status === 500) {
        MySwal.fire({
            title: titulo,
            text: "Erro, por favor, contate o suporte do sistema",
            icon: "error",
            timer: 1500,
            showConfirmButton: false,
        })
    }
}