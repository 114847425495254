import * as React from "react";
import {
    ProgressSteps,
    ORIENTATION,
    Step, NumberedStep
} from "baseui/progress-steps";
import "./BarraProgressoDocumento.css";
import styled from 'styled-components'
import {useEffect, useState} from "react";
import { BarraProgressoDocumentoType} from "../types/BarraProgressoDocumento";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPenNib, faTrash} from "@fortawesome/free-solid-svg-icons";
import {useApi} from "../hooks/useApi";
type MyArrayType = BarraProgressoDocumentoType[]

export default function BarraProgressoDocumento ({documento_id}: any) {
    console.log(documento_id)
    let token = localStorage.getItem('authToken')
    let api = useApi(token);
    const [current, setCurrent] = React.useState(0);
    const [dados, setDados] = useState<MyArrayType>([])
    useEffect(() => {
        handleDados()
    },[documento_id])

    async function handleDados() {
        if(documento_id != "" && documento_id != undefined) {
            let response = await api.documento.pegarPassosDocumento(documento_id);
            setCurrent(1)
            setDados(response.data)
        }


    }
    return (

        <ProgressSteps
            current={current}
            orientation={ORIENTATION.horizontal}
            alwaysShowDescription
        >
            {dados.map(({transicao_id, descricao, datahora, }) => (

                    <NumberedStep   key={transicao_id} title={descricao}>
                        <div className='text-center'>
                            <p className="labelStep">{datahora}</p>
                        </div>
                    </NumberedStep>


            ))}

        </ProgressSteps>
    );
}